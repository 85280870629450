function onFetchInit(data) {
  let form = {}
  data.forEach((e, i) => {
    form['name' + i] = e.name
    form['content ' + i] = e.content
    form['status' + i] = e.status
  })
  return {
    form,
    typeData: []
  }
}

function onSubmit({ detail, form }) {
  return {
    list: detail.map((e, i) => {
      return {
        ...e,
        name: form['name' + i],
        content: form['content ' + i],
        status: form['status' + i]
      }
    })
  }
}

export default {
  type: 'cardForm',
  url: '/config/productType/list',
  edit: '/config/productType/update',
  onFetchInit,
  onSubmit,
  data: (netData = []) => {
    if (!Array.isArray(netData)) return []
    return (netData || []).map((e, i) => {
      return {
        form: [
          {
            name: e.name || '',
            type: 'input',
            key: 'content ' + i
          },
          {
            name: '页面显示',
            type: 'radio',
            key: 'status' + i,
            typeData: [
              {
                name: '显示',
                value: '0'
              },
              {
                name: '不显示',
                value: '1'
              }
            ]
          }
        ]
      }
    })
  }
}
